<script>
export default {
  name: 'FlagsMixin',
  computed: {
    flags() {
      return {
        es: 'es',
        en: 'gb',
      };
    },
  },
};
</script>
