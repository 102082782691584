<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              depressed
              dense
              text
            >
                <CountryFlag :country="country_flag" />
                <v-icon right>
                    mdi-arrow-down-drop-circle
                </v-icon>
            </v-btn>
        </template>
        <v-list
          v-for="(c, l) in flags"
          :key="c"
        >
            <v-list-item
              @click="$i18n.locale = l"
            >
                <CountryFlag :country="c" />
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import FlagsMixin from '@/components/FlagsMixin';

export default {
  name: 'CountrySelector',
  mixins: [
    FlagsMixin,
  ],
  components: {
    CountryFlag,
  },
  computed: {
    country_flag() {
      return this.flags[this.$i18n.locale];
    },
  },
};
</script>
