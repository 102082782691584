<template>
  <!--Toolbar-->
  <v-app-bar
    app
    dense
    :dark="!positive"
    color="rgba(0,0,0,0)"
    flat
    absolute
    class="pa-0"
    :height="$vuetify.breakpoint.smAndDown ? 64 : 84"
    :scroll-threshold="threshold"
  >
    <!-- RESPONSIVE BURGER MENU -->
    <div class="d-inline-flex justify-space-between align-center w-100 h-100 pl-4" v-if="$vuetify.breakpoint.smAndDown">
      <div class="d-inline-flex align-center h-100">
        <router-link :to="{name: 'Home'}">
          <img
            style="height: 75%; cursor: pointer;"
            :src="positive ? require('@/assets/img/1x_PNG/logo_principal_positivo.png') : require('@/assets/img/1x_PNG/logo_principal_negativo.png')"
          />
        </router-link>
      </div>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="fill-height"
        color="#0d1064"
        app
      >
        <v-list-item>
          <div style="width: 40%;" class="py-3">
            <img
              class="w-100"
              :src="require('@/assets/img/1x_PNG/logo_principal_negativo.png')"
              @click="window.scrollTo({top: 0, left: 0, behavior: 'smooth'})"
            />
          </div>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            v-for="section in site_sections"
            :key="`section_list_${section.id}`"
            link
            @click.stop="drawer = !drawer"
            v-scroll-to="{el: '#' + section.id, offset: -25}"
          >
            <v-list-item-content>
              <v-list-item-title>{{ section.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-if="portfolio">
            <v-list-item
              link
              v-for="(item, i) in portfolio"
              :key="`portfolio_${i}`"
              @click.stop="$router.push(item.route)"
            >
              <v-list-item-content>
                <v-list-item-title class="white--text">{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            link
            v-scroll-to="{el: '#' + contact.id, offset: -25}"
          >
            <v-list-item-content>
              <v-list-item-title class="white--text">{{ contact.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <CountrySelector />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </div>

    <!-- APPBAR ICON -->
    <div v-else class="d-inline-flex justify-space-between align-center w-100 h-100 py-4 px-12">
      <div style="height: 100%; cursor: pointer;">
        <router-link :to="{name: 'Home'}">
          <img
            :src="positive ? require('@/assets/img/1x_PNG/logo_principal_positivo.png') : require('@/assets/img/1x_PNG/logo_principal_negativo.png')"
            height="100%"
          />
        </router-link>
      </div>
      <!-- APPBAR BUTTONS -->
      <div class="d-inline-flex align-center">
        <p
          v-for="section in site_sections"
          :key="`section_toolbar_${section.id}`"
          class="header__btn mr-6"
          v-scroll-to="{el: `#${section.id}`, offset: -25}"
        >
          {{section.title}}
        </p>
        <template v-if="portfolio">
          <p
            class="header__btn mr-6"
            v-for="(item, i) in portfolio"
            :key="`portfolio_${i}`"
            @click="$router.push(item.route)"
          >
            {{ item.title }}
          </p>
        </template>
        <div
          v-ripple
          class="header__contact-btn py-2 px-10"
           v-scroll-to="{el: '#' + contact.id, offset: -25}"
        >
          {{ contact.title }}
        </div>
        <div>
          <CountrySelector />
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import CountrySelector from '@/components/CountrySelector';

export default {
  props: {
    site_sections: {
      type: Array,
    },
    portfolio: {
      type: Array,
    },
    contact: {
      type: Object,
    },
    positive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CountrySelector,
  },
  data() {
    return {
      drawer: null,
    };
  },
  computed: {
    threshold() {
      return 0; // window.innerHeight / 4;
    },
  },
};
</script>
<style lang="scss" scoped>
.header__btn {
  font-size: 1rem;
  font-weight: 600;
  line-height: 2em;
  cursor: pointer;
}
.header__contact-btn {
  cursor: pointer;
  background-color: $verdjavu;
  color: white;
  font-size: 0.9rem;
  font-weight: 900;
  line-height: 2em;
  border-radius: 3rem;
  text-transform: uppercase;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  transition: all .2s ease;

  &:hover{
    background-color: white;
    color: $verdjavu;
  }
}
</style>
